// extracted by mini-css-extract-plugin
export var contactpadding = "contact-module--contactpadding--3_gWG";
export var colpd = "contact-module--colpd--3frZs";
export var colmap = "contact-module--colmap--20AR0";
export var header = "contact-module--header--3DM_a";
export var contactflex = "contact-module--contactflex--2IBwd";
export var plive = "contact-module--plive--2xuYQ";
export var plivedes = "contact-module--plivedes--2ojYy";
export var contactpagerow = "contact-module--contactpagerow--1AETk";
export var headerpagecontact = "contact-module--headerpagecontact--1rurG";
export var headerpagecontacta = "contact-module--headerpagecontacta--2U-ZV";