import * as React from "react"
import Header from "../components/header"
import HeaderTop from "../components/headertop"
import FooterForm from "../components/form-footer"
import Footer from "../components/footer"
import Bgcontact from "../components/bgcontact"
import { Container, Row, Col } from "react-bootstrap"
import * as contactStyles from "../components/contact.module.css"
import { Icon } from '@iconify/react';
import mobileIcon from '@iconify/icons-bytesize/mobile';
import locationIcon from '@iconify/icons-bytesize/location';
import faxIcon from '@iconify/icons-cil/fax';
import Formcontact from "../components/form-contact"
import SEO from "../components/seo"

// styles

// markup
const IndexPage = () => {
  return (
    <main>
      <SEO title="ติดต่อเรา" />
      <HeaderTop />
        <Header />
          <Bgcontact  />
            <Container fluid="xl" className={contactStyles.contactpadding}>
              <Row>
                <Col md={6}>
                  <h2 className={contactStyles.headerpagecontact}>กรอกแบบฟอร์มติดต่อเรา</h2>
                  <p>เมื่อได้ข้อความจากท่านแล้ว ทางเราจะติดต่อกลับอย่างเร็วที่สุด</p>
                  <Formcontact />
                </Col>
                <Col>
                  <h1 className={contactStyles.headerpagecontact}>ติดต่อสำนักงาน</h1>
                  <p>ช่องทางอื่นๆ สำหรับติดต่อสำนักงาน</p>
                        <ul className={contactStyles.contactflex}>
                            <li><Icon icon={locationIcon} height={23} /></li>
                            <li><p className={contactStyles.plive}><b>ที่อยู่</b></p><p className={contactStyles.plivedes}>99/65 หมู่ที่ 2 ตำบลท่าเสา อำเภอกระทุ่มแบน จ.สมุทรสาคร 74110</p></li>
                        </ul>
                        <ul className={contactStyles.contactflex}>
                            <li><Icon icon={mobileIcon} height={23} /></li>
                            <li><p className={contactStyles.plive}><b>เบอร์โทรศัพท์</b></p><p className={contactStyles.plivedes}>034-471-614</p></li>
                        </ul>
                        <ul className={contactStyles.contactflex}>
                            <li><Icon icon={faxIcon} height={23} /></li>
                            <li><p className={contactStyles.plive}><b>แฟกซ์</b></p><p className={contactStyles.plivedes}>034-471-615</p></li>
                        </ul>
                </Col>
              </Row>
              <Row className={contactStyles.contactpagerow}>
                <Col>
                <h3 className={contactStyles.headerpagecontacta}>แผนที่</h3>
                <iframe
                    title="Map ThaiPolyPack"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3877.674351415143!2d100.2456482!3d13.6166903!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2c13130ebb36b%3A0x4f9361024fec1965!2sTHAI%20POLY%20PACK%20COMPANY%20LIMITED!5e0!3m2!1sen!2sth!4v1628847169623!5m2!1sen!2sth"
                    width="100%"
                    height="400"
                    frameBorder="0"
                    style={{ border: 0, borderRadius:"10px" }}
                    allowFullScreen=""
                    aria-hidden="false"
                  />
                </Col>
              </Row>
            </Container>
        <FooterForm />
      <Footer />
    </main>
  )
}

export default IndexPage