import React from "react"
import * as formcontactStyles from "./formcontact.module.css"

export default function Formcontact () {
    return (
        <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contactform">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contactform" />
            <div className={formcontactStyles.contactflex}>
                <input className={formcontactStyles.formcontact} type="text" placeholder="ชื่อ-นามสกุล" name="ชื่อ-นามสกุล" />
                <input className={formcontactStyles.formcontact} type="text" placeholder="บริษัท" name="บริษัท" />
            </div>
            <div className={formcontactStyles.contactflex}>
                <input className={formcontactStyles.formcontact} type="email" placeholder="อีเมล" name="อีเมล" />
                <input className={formcontactStyles.formcontact} type="tel" placeholder="เบอร์โทรศัพท์" name="เบอร์โทรศัพท์" />
            </div>
            <div className={formcontactStyles.contactflex}>
                <textarea className={formcontactStyles.formcontact} name="message" rows="5" placeholder="รายละเอียด"></textarea>
            </div>
                <button className={formcontactStyles.buttomsubmit} type="submit">Submit</button>
        </form>
    )
}